require('./main.css')
import React, { useState } from "react";
import { render } from "react-dom";

console.log("Running on " + process.env.ENV_DESCRIPTION);

// jump-off point for any active content //
const activeContent = <React.Fragment>...</React.Fragment>;

render(activeContent, document.getElementById("root"))
